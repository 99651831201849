:root {
  --deep-blue: #031728;
  --mild-blue: #041C2D;
  --text-white: #ffffff;
  --highlight: #66fcf1;
  --footer-bg: #0e0d0d;
  --footer-text: #fefefe;
  --footer-about: #868e9a;
}


/* General Styles */
body {
  font-family: 'Roboto', sans-serif; /* Main font */
    color: var(--text-white); /* Primary text color */
    background-color: var(--deep-blue); /* Background color */
  }
  
  a {
    color: var(--text-white); /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  /* Header Styles */
  .header {
    background-color: var(--mild-blue);
    padding: 15px 0; /* Padding around header */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .header .logo {
    font-size: 2.5em; /* Logo font size */
    font-weight: bold; /* Bold logo */
  }
  
  .header nav ul {
    list-style: none; /* Remove default list style */
    padding: 0; /* Remove padding */
    display: flex; /* Flex layout for navigation items */
    justify-content: center; /* Center navigation items */
  }
  
  .header nav ul li {
    margin: 0 10px; /* Margin between navigation items */
  }
  
  .header nav ul li a {
    color: var(--text-white); /* Navigation link color */
    font-weight: bold; /* Bold navigation links */
    font-size: 1.5em;
  }
  
  /* Introduction Styles */
  .introduction {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: left; /* Center text in introduction */
    padding: 50px; /* Padding around introduction */
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
    max-width: 1700px;
  }
  
  .introduction h1 {
    font-size: 4.0em; /* Large font size for main heading */
    color: var(--text-white); /* Color for main heading */
    font-family: 'Roboto', sans-serif;
  }
  
  .introduction p {
    font-size: 1.2em; /* Font size for introduction text */
  }

  .text-content {
    flex: 5;
    margin: 0 auto;
    max-width: 500px;
    font-family: 'Roboto', sans-serif;
  }

  .image-content {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .image-content img {
    max-width: 40%;
    height: auto;
  }

  .skills-section {
    background-color: var(--deep-blue);
    color: #fff;
    padding: 40px;
    margin: 0 auto;
    max-width: 1300px;
    padding: 40px;
  }
  
  .skills-section h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    grid-template-rows: repeat(2, auto); /* Creates two rows */
    gap: 20px;
  }
  
  .skill-item {
    background-color: var(--mild-blue);
    padding: 20px;
    border-left: 3px solid var(--highlight); /* Replace with the color of your choice */
    margin-bottom: 20px;
  }
  
  .skill-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .skill-item p {
    font-size: 1rem;
  }
  
  .skill-item img {
    width: 50px;
    height: 50px;
    /* You may need to adjust the size of the image based on your actual icons */
  }

  .footer {
    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding-top: 50px
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    text-align: center;
  }
  
  .footer-section {
    flex: 1;
  }

  .footer-section.about {
    /* Your specific styles for the "About Me" section */
    max-width: 400px; /* Set a maximum width if desired */
    color: var(--footer-about);
    /* More styles like font size, line height, etc., can be added here */
  }
  
  
  .footer-section h3 {
    color: var(--footer-text);
    margin-bottom: 20px;
  }

  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #000000;
  }
  
  .footer-bottom a {
    color: #64ffda;
    margin: 0 15px;
  }

  .footer a[href*="https://www.linkedin.com/in/michael-oates-00/"] {
    color: #0e76a8; /* LinkedIn blue */
    font-size: 2.5em;
  }
  
  .footer a[href*="https://www.instagram.com/michaeljoates/"] {
    color: #e1306c; /* Instagram pink */
    font-size: 2.5em;
  }
  
  /* Adjust margins, paddings, and colors to fit
  /* Other component styles can be added here following the same pattern. */
  